import React from "react";
import { Helmet } from "react-helmet";
 import "./partner-form.css";
import { useState } from "react";

function PartnerForm() {
  const [showContent, setshowContent] = useState(new Array(18).fill(false));

  // Function to toggle content visibility for a specific section
  const toggleSection = (index) => {
    setshowContent((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="container tnC-page">
      <Helmet>
        <title>Partner Registration Form </title>
        <meta
          name="description"
          content="Partner Registration Form"
        />
      </Helmet>
      <div className="formszie ">
      <iframe aria-label='Partner Registration Form' frameborder="0"  className="formszie" src='https://forms.zohopublic.in/communitifyinfotechnologiespr1/form/Partners/formperma/S6moKkwbROLE0LLoH9zLg9h2KOHrXobKPU1NThKBog4'></iframe>
      </div>
    </div>
  );
}

export default PartnerForm;