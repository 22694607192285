import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./languageswitche2.css";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="language-swatcher-2" ref={dropdownRef}>
      <h5 className="activelanguage" onClick={toggleDropdown}>
        <i class="fa-regular fa-globe right-icon"></i>{" "}
        {i18n.language === "english"
          ? "English"
          : i18n.language === "spanish"
          ? "Español"
          : i18n.language === "hindi"
          ? "हिंदी"
          : i18n.language === "german"
          ? "Deutsch"
          : i18n.language === "french"
          ? "Français"
          : i18n.language === "russian"
          ? "Русский"
          : i18n.language === "arabic"
          ? "عربي"
          : i18n.language === "portuguese"
          ? "português"
          : i18n.language === "italian"
          ? "italiana"
          : i18n.language === "dutch"
          ? "Nederlands"
          : i18n.language === "japanese"
          ? "日本語"
          : i18n.language === "korean"
          ? "한국인"
          : i18n.language === "swedish"
          ? "svenska"
          : i18n.language === "turkish"
          ? "Türkçe"
          : i18n.language === "bengali"
          ? "বাংলা"
          : i18n.language === "tamil"
          ? "தமிழ்"
          : i18n.language === "telugu"
          ? "తెలుగు"
          : i18n.language === "marathi"
          ? "मराठी"
          : i18n.language === "kannada"
          ? "ಕನ್ನಡ"
          : i18n.language === "malayalam"
          ? "മലയാളം"
          : i18n.language === "gujarati"
          ? "ગુજરાતી"
          : ""}{" "}
        <i class="fa-solid fa-angle-down left-icon"></i>
      </h5>
      {showDropdown && (
        <ul className="dropdown-language">
          {/* <li onClick={() => changeLanguage("arabic")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Arabic</div>
              <div className="lang-with-oun">عربي</div>
            </div>
          </li> */}
          <li onClick={() => changeLanguage("bengali")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Bengali</div>
              <div className="lang-with-oun">বাংলা</div>
            </div>
          </li>
          {/* <li onClick={() => changeLanguage("dutch")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Dutch</div>
              <div className="lang-with-oun">Nederlands</div>
            </div>
          </li> */}
          <li onClick={() => changeLanguage("english")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">English</div>
              <div className="lang-with-oun">English</div>
            </div>
          </li>
          {/* <li onClick={() => changeLanguage("french")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">French</div>
              <div className="lang-with-oun">Français</div>
            </div>
          </li>
          <li onClick={() => changeLanguage("german")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">German</div>
              <div className="lang-with-oun">Deutsch</div>
            </div>
          </li> */}
          <li onClick={() => changeLanguage("gujarati")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Gujarati</div>
              <div className="lang-with-oun">ગુજરાતી</div>
            </div>
          </li>
          <li onClick={() => changeLanguage("hindi")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Hindi</div>
              <div className="lang-with-oun">हिंदी</div>
            </div>
          </li>
          {/* <li onClick={() => changeLanguage("italian")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Italian</div>
              <div className="lang-with-oun">Italiana</div>
            </div>
          </li>
          <li onClick={() => changeLanguage("japanese")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Japanese</div>
              <div className="lang-with-oun">日本語</div>
            </div>
          </li> */}
          <li onClick={() => changeLanguage("kannada")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Kannada</div>
              <div className="lang-with-oun">ಕನ್ನಡ</div>
            </div>
          </li>
          {/* <li onClick={() => changeLanguage("korean")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Korean</div>
              <div className="lang-with-oun">한국인</div>
            </div>
          </li> */}
          <li onClick={() => changeLanguage("malayalam")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Malayalam</div>
              <div className="lang-with-oun">മലയാളം</div>
            </div>
          </li>
          <li onClick={() => changeLanguage("marathi")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Marathi</div>
              <div className="lang-with-oun">मराठी</div>
            </div>
          </li>
          {/* <li onClick={() => changeLanguage("portuguese")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Portuguese</div>
              <div className="lang-with-oun">Português</div>
            </div>
          </li>
          <li onClick={() => changeLanguage("russian")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Russian</div>
              <div className="lang-with-oun">Русский</div>
            </div>
          </li>
          <li onClick={() => changeLanguage("spanish")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Spanish</div>
              <div className="lang-with-oun">Español</div>
            </div>
          </li>
          <li onClick={() => changeLanguage("swedish")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Swedish</div>
              <div className="lang-with-oun">svenska</div>
            </div>
          </li> */}
          <li onClick={() => changeLanguage("tamil")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Tamil</div>
              <div className="lang-with-oun">தமிழ்</div>
            </div>
          </li>
          <li onClick={() => changeLanguage("telugu")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Telugu</div>
              <div className="lang-with-oun">తెలుగు</div>
            </div>
          </li>
          {/* <li onClick={() => changeLanguage("turkish")}>
            <div className="language-drop-contactyiner">
              <div className="lang-with-eng">Turkish</div>
              <div className="lang-with-oun">Türkçe</div>
            </div>
          </li> */}
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;
