import React, { useEffect } from 'react';

const AppRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Redirect based on the user agent
    if (/android/i.test(userAgent)) {
      // Redirect to Google Play
      window.location.href = 'https://play.google.com/store/apps/details?id=com.communitify.kootami';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to Apple Store
      window.location.href = 'https://apps.apple.com/app/kootami/id6736614675';
    } else {
      // Redirect to your webpage for desktop users
      window.location.href = 'https://kootami.com/#contact-us';
    }
  }, []);

  return null; // No UI needed for this component
};

export default AppRedirect;
