import React from 'react';

function Testimonial({ rating, reviewText, reviewerName, reviewerCompany }) {
  return (
    <div className="testimonial">
    
      <div className="star-rating">
        {Array.from({ length: 5 }, (_, i) => (
          <i key={i} className={`fa-solid fa-star ${i < rating ? 'star-active' : ''}`}></i>
        ))}
      </div>

      <div className="review-text">
        <p className="review-para">"{reviewText}"</p>
      </div>

      <div className="reviewer-info">
        <div className="reviewer-logo">
          <i className="fa-regular fa-user"></i>
        </div>

        <div className="reviewer-name-company">
          <h5>{reviewerName}</h5>
          <h6>{reviewerCompany}</h6>
        </div>
      </div>
      </div>
  
  );
}

export default Testimonial;
