import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./languageswitcher.css";
const languageOptions = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Español" },
  { value: "hindi", label: "हिंदी" },
  { value: "german", label: "Deutsch" },
];

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
  };

  return (
    <div className="lang-swatcher react-selecttwo">
      <Select
        options={languageOptions}
        onChange={changeLanguage}
        value={languageOptions.find((option) => option.value === i18n.language)}
      />
    </div>
  );
};

export default LanguageSwitcher;


