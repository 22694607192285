import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-scroll';
import LanguageSwitcher from '../languageswitcher/languageswitcher';
import Languageswitcher2 from '../languageswitcher2/languageswitcher.js'
import '../../i18n.js'

import Logo from '../logo/sitelogo.js'

const Header = () => {
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 0.2 * window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isResponsiveActive, setResponsiveActive] = useState(false);

  useEffect(() => {
    const handleDocumentClick = () => {
      if (isResponsiveActive) {
        setResponsiveActive(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isResponsiveActive]);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setResponsiveActive(!isResponsiveActive);
  };

  const handleNavLinkClick = () => {
    setResponsiveActive(false);
  };

  const toggleChat = () => {
    const chatContainer = document.getElementById('chat-container');
    chatContainer.classList.toggle('active');
  };
  
  return (
    <div className={`association-header ${isSticky ? 'sticky-header' : ''}`}>
     <header>
      <div className='container'>
        <div className={`header-wrapper ${isResponsiveActive ? 'responsive-wrapper-is-active' : ''}`}>
          <div className='logolang-wpaper'>
              <div className='site-logo-wrapper'>
                 <Logo/>
              </div>
              <div className='site-language-wrapper'>
                {/* <LanguageSwitcher /> */}
                <Languageswitcher2 />
              </div>
          </div>
          <div className='navigation-bar-wrapper'>
            <nav className='site-nav-bar'>
              <ul className='site-nav-wrap'>
                  <li className='site-nav'>
                    <Link className='site-nav-link'  to="/#features" >{t('headermenu.FEATURES')}</Link>
                  </li>
                  <li className='site-nav'>
                    <Link className='site-nav-link'   to="/#about-us"  >{t('headermenu.ABOUT')}</Link>
                  </li>
                  {/* <li className='site-nav'>
                    <Link className='site-nav-link'  to="/#pricing" >{t('headermenu.PRICING')}</Link>
                  </li> */}
                  <li className='site-nav'>
                    <a className='site-nav-link' href="/register"  >{t('headermenu.CONTACTUS')}</a>
                    {/* <a className='site-nav-link' onClick={toggleChat} >{t('headermenu.CONTACTUS')}</a> */}
                  </li>
                  <li className='site-nav login-btn'>
                    <Link className='site-nav-link login-hyper' to="/deeplink"  ><span>{t('herobanner.downloadapp')}</span></Link>
                  </li>
              </ul>
            </nav>
            <div className="responsive-humbugur-menu">
              <button id="responsive-menu-pro-button"  className="responsive-menu-pro-button responsive-menu-pro-spin responsive-menu-pro-accessible" type="button" aria-label="Menu" onClick={handleButtonClick}>
                  <span className="responsive-menu-pro-box">
                      <span className="responsive-menu-pro-inner"></span>
                  </span>
              </button>
            </div>

          </div>

        </div>
      </div>
     </header>
    </div>
  );
};

export default Header;
