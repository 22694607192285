import React from "react";
import { Helmet } from "react-helmet";
import "./onboarding-form.css";
import { useState } from "react";

function RegistrationForm() {
  const [showContent, setshowContent] = useState(new Array(18).fill(false));

  // Function to toggle content visibility for a specific section
  const toggleSection = (index) => {
    setshowContent((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="container tnC-page">
      <Helmet>
        <title>Registration Form </title>
        <meta
          name="description"
          content="Our Privacy Policy explains how we collect, use, and protect your personal information. Please review it carefully to understand our practices."
        />
      </Helmet>
      <div className="formszie ">
      <iframe aria-label='Organization Registration Form' frameborder="0" className="formszie" src='https://forms.zohopublic.in/communitifyinfotechnologiespr1/form/PatientIntakeForm/formperma/m50SsoaylwPtHYJ94sE899kxbR1SYyvVOdwRom_uHoc'></iframe>
      </div>
    </div>
  );
}

export default RegistrationForm;