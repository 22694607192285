import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <Link to='/'>
<svg width="150" height="31" viewBox="0 0 150 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M144.195 10.2857H150V30.2857H144.195V10.2857Z" fill="#111111"/>
<path d="M144.195 4.21428C144.195 2.5835 145.507 1.2381 147.098 1.2381C148.688 1.2381 150 2.5835 150 4.21428C150 5.84508 148.688 7.19048 147.098 7.19048C145.507 7.19048 144.195 5.84508 144.195 4.21428Z" fill="#342B2B"/>
<path d="M107.972 30.2857V8.37509H113.94V10.8546C116.191 7.91968 118.979 7.66667 120.251 7.66667C124.703 7.66667 126.415 10.551 126.953 11.6642C127.98 9.94375 130.084 7.66667 133.753 7.66667C137.031 7.66667 138.645 9.23533 139.477 10.3486C140.846 12.2208 140.944 14.0931 140.944 16.522V30.2857H134.976V18.3437C134.976 15.763 134.438 14.5991 133.998 14.0425C133.215 13.0305 132.139 12.9293 131.552 12.9293C129.937 12.9293 128.91 13.6883 128.274 14.8521C127.491 16.1678 127.442 17.9389 127.442 19.1027V30.2857H121.474V18.3437C121.474 15.763 120.936 14.5991 120.496 14.0425C119.713 13.0305 118.637 12.9293 118.05 12.9293C116.435 12.9293 115.408 13.6883 114.772 14.8521C113.989 16.1678 113.94 17.9389 113.94 19.1027V30.2857H107.972Z" fill="#111111"/>
<path d="M97.8413 11.2097V8.37527H103.793V30.2914H97.8413V27.457C95.6949 30.6457 92.8656 31 91.3534 31C84.8655 31 80.5728 25.7361 80.5728 19.308C80.5728 12.8294 84.8167 7.66667 91.0607 7.66667C92.6705 7.66667 95.8413 7.97035 97.8413 11.2097ZM92.5729 13.133C89.1582 13.133 86.7192 15.8156 86.7192 19.308C86.7192 22.8004 89.1582 25.5336 92.5729 25.5336C95.9876 25.5336 98.4266 22.8004 98.4266 19.308C98.4266 15.8156 95.9876 13.133 92.5729 13.133Z" fill="#111111"/>
<path d="M79.644 13.7022H75.2835V30.2857H69.3718V13.7022H65.0155V8.46004H69.3718V1H75.2835V8.46004H79.644V13.7022Z" fill="#111111"/>
<path d="M32.2755 7.66667C36.3446 7.66667 39.1235 9.03326 40.9596 10.7542C43.0934 12.7281 44.582 15.765 44.582 19.3586C44.582 22.9017 43.0934 25.9385 40.9596 27.9125C39.1235 29.6334 36.3446 31 32.2755 31C28.2065 31 25.4276 29.6334 23.5915 27.9125C21.4577 25.9385 19.969 22.9017 19.969 19.3586C19.969 15.765 21.4577 12.7281 23.5915 10.7542C25.4276 9.03326 28.2065 7.66667 32.2755 7.66667ZM32.2755 25.5336C35.7988 25.5336 38.3296 22.6992 38.3296 19.3586C38.3296 15.9168 35.7492 13.133 32.2755 13.133C28.8019 13.133 26.2215 15.9168 26.2215 19.3586C26.2215 22.6992 28.7523 25.5336 32.2755 25.5336Z" fill="#486E6B"/>
<path d="M53.1734 7.66667C57.1657 7.66667 59.8921 9.03326 61.6936 10.7542C63.7871 12.7281 65.2477 15.765 65.2477 19.3586C65.2477 22.9017 63.7871 25.9385 61.6936 27.9125C59.8921 29.6334 57.1657 31 53.1734 31C49.1811 31 46.4546 29.6334 44.6532 27.9125C42.5597 25.9385 41.0991 22.9017 41.0991 19.3586C41.0991 15.765 42.5597 12.7281 44.6532 10.7542C46.4546 9.03326 49.1811 7.66667 53.1734 7.66667ZM53.1734 25.5336C56.6301 25.5336 59.1132 22.6992 59.1132 19.3586C59.1132 15.9168 56.5814 13.133 53.1734 13.133C49.7653 13.133 47.2336 15.9168 47.2336 19.3586C47.2336 22.6992 49.7166 25.5336 53.1734 25.5336Z" fill="#F56060"/>
<path d="M0 1.2381H5.99963V16.4404L13.9664 8.48565H21.7364L11.7534 18.0515L22.5232 30.2857H14.5565L6.63894 20.9716L5.99963 21.5758V30.2857H0V1.2381Z" fill="#111111"/>
</svg>


      </Link>
        
    );
  };
  
  export default Logo;
  