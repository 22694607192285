import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TypingText = () => {
  const { t } = useTranslation();

  const sentences = [
    t('herobanner.efficient'),
    t('herobanner.EffortlessConnections'),
    t('herobanner.EasyFundraising')

  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [forwards, setForwards] = useState(true);
  const [skipCount, setSkipCount] = useState(0);

  const speed = 70;

  useEffect(() => {
    const handleAnimation = () => {
      const skipDelay = 15;

      if (forwards) {
        if (offset >= sentences[currentIndex].length) {
          setSkipCount((prevSkipCount) => prevSkipCount + 1);
          if (skipCount === skipDelay) {
            setForwards(false);
            setSkipCount(0);
          }
        }
      } else if (offset === 0) {
        setForwards(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sentences.length);
      }

      if (skipCount === 0) {
        setOffset((prevOffset) => (forwards ? prevOffset + 1 : prevOffset - 1));
      }
    };

    const interval = setInterval(handleAnimation, speed);

    return () => clearInterval(interval);
  }, [forwards, offset, currentIndex, skipCount, sentences]);

  const updateSentence = () => {
    return sentences[currentIndex]?.substring(0, offset);
  };

  return (
    <span className="sentence">{updateSentence()} | </span>
  );
};

export default TypingText;
