import React, { useState } from 'react';
import './slider.css';

function Slider({ children }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === React.Children.count(children) - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? React.Children.count(children) - 1 : prevSlide - 1));
  };

  return (
    <div>
      <div className="slider-container">
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? 'active' : ''} ${
              index === currentSlide - 1 || (currentSlide === 0 && index === React.Children.count(children) - 1)
                ? 'previous'
                : ''
            } ${
              index === currentSlide + 1 || (currentSlide === React.Children.count(children) - 1 && index === 0)
                ? 'next'
                : ''
            }`}
          >
            {child}
          </div>
        ))}
      </div>
      <div className='nav-btn'>
        <button onClick={prevSlide} disabled={currentSlide === 0} className={currentSlide === 0 ? 'disabled' : ''}>
          <i className="fa-solid fa-arrow-left"></i>
        </button>
        <button onClick={nextSlide} disabled={currentSlide === React.Children.count(children) - 1} className={currentSlide === React.Children.count(children) - 1 ? 'disabled' : ''}>
          <i className="fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
}

export default Slider;
