// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_en from "./translation/english/global.json";
import translation_hindi from "./translation/hindi/global.json";
import translation_spanish from "./translation/spanish/global.json";
import translation_german from "./translation/german/global.json";
import translation_french from "./translation/french/global.json";
import translation_russian from "./translation/russian/global.json";
import translation_arabic from "./translation/arabic/global.json";
import translation_portuguese from "./translation/portuguese/global.json";
import translation_italian from "./translation/italian/global.json";
import translation_dutch from "./translation/dutch/global.json";
import translation_japanese from "./translation/japanese/global.json";
import translation_korean from "./translation/korean/global.json";
import translation_swedish from "./translation/swedish/global.json"
import translation_turkish from "./translation/turkish/global.json"
import translation_bengali from "./translation/bengali/global.json"
import translation_tamil from "./translation/tamil/global.json"
import translation_telugu from "./translation/telugu/global.json"
import translation_marathi from "./translation/marathi/global.json"
import translation_kannada from "./translation/kannada/global.json"
import translation_gujarati from "./translation/gujarati/global.json"
import translation_malayalam from "./translation/malayalam/global.json"








const resources = {
  english: {
    translation: translation_en,
  },
  spanish: {
    translation: translation_spanish,
  },
  hindi: {
    translation: translation_hindi,
  },
  german: {
    translation: translation_german,
  },
  malayalam: {
    translation: translation_malayalam,
  },
  french: {
    translation: translation_french,
  },
  russian: {
    translation: translation_russian,
  },
  arabic: {
    translation: translation_arabic,
  },
  portuguese: {
    translation: translation_portuguese,
  },
  italian: {
    translation: translation_italian,
  },
  dutch: {
    translation: translation_dutch,
  },
  japanese: {
    translation: translation_japanese,
  },
  korean: {
    translation: translation_korean,
  },
  swedish: {
    translation: translation_swedish,
  },
  turkish: {
    translation: translation_turkish,
  },
  bengali: {
    translation: translation_bengali,
  },
  tamil: {
    translation: translation_tamil,
  },
  telugu: {
    translation: translation_telugu,
  },
  marathi: {
    translation: translation_marathi,
  },
  kannada: {
    translation: translation_kannada,
  },
  gujarati: {
    translation: translation_gujarati,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "english", // Default language
  fallbackLng: "english",
  keySeparator: ".", // We do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // Not needed for react!!
  },
});

export default i18n;
