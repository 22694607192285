import React, { useState, useEffect } from 'react';
import './tab.css';

function TabContent({ tabName, content, isActive }) {
  return (
    <div className={`tabcontent pricingtab-${tabName} ${isActive ? 'active' : ''}`} style={{ display: isActive ? 'flex' : 'none' }}>
      {content}
    </div>
  );
}

function Tabs({ tabData }) {
  const [activeTab, setActiveTab] = useState(null);

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (tabData.length > 0 && !activeTab) {
      setActiveTab(tabData[0].tabName);
    }
  }, [tabData, activeTab]);

  return (
    <div className="tabs-wrapper">
      {tabData.map((tab, index) => (
        <TabContent key={index} tabName={tab.tabName} content={tab.content} isActive={activeTab === tab.tabName} />
      ))}

      <div className="button-wrapper">
        <div className="buttons-container">
          {tabData.map((tab, index) => (
            <button
              key={index}
              className={`montserrattablinks ${activeTab === tab.tabName ? 'active montserrat' : 'montserrat'}`}
              onClick={() => openTab(tab.tabName)}
            >
              {tab.buttonContent}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tabs;
