import React from 'react';

const Sociallinks = () => {
    return (
        <div className='association-social-links'>
            <ul className="social-icons">
                <li><span><a className="social-facebook" href="https://www.facebook.com/kootami" target="_blank"><i className="fa-brands fa-facebook-f"></i></a></span></li>
                <li><span><a className="social-x" href="https://x.com/kootamiofficial" target="_blank"><i className="fa-brands fa-x-twitter"></i></a></span></li>
                <li><span><a className="social-linkdin" href="https://linkedin.com/company/kootami" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></span></li>
                <li><span><a className="social-instagram" href="https://www.instagram.com/kootami_official/" target="_blank"><i className="fa-brands fa-instagram"></i></a></span></li>
                <li><span><a className="social-facebook" href="https://www.youtube.com/@kootami" target="_blank"><i className="fa-brands fa-youtube"></i></a></span></li>

            </ul>
        </div>

    );
};
export default Sociallinks;
    